import * as UPSELL_FROM from 'modules/Analytics/constants/upsellFrom';
import analytics from 'modules/Analytics';
import FilledButton from 'primitives/Buttons/FilledButton';
import H3 from 'primitives/Typography/Headings/H3';
import qs from 'qs';
import RecurlyHOC from 'components/Recurly/RecurlyHOC';
import {
  ConfirmationButtonWrap,
  DefaultObj,
  HeroContainer,
  HeroStyles,
  HeroText,
  Logo,
  RecurlyFormWrap,
} from 'components/Recurly';
import { RECURLY_PLAN_CODE_MAP } from 'constants/subscriptionConstants';

const PLANMAP: DefaultObj = RECURLY_PLAN_CODE_MAP;

function recurlyConfirmation() {
  const explainText =
    'Get started by choosing from thousands of stations to play. Or simply search for your favorite artist and hit play.';
  let planCode;

  if (__CLIENT__) {
    const { subscriptionId } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    planCode = subscriptionId;
  }

  const heroText = `Welcome to iHeart ${PLANMAP[planCode] || ''}`.trim();
  const redirect = () => {
    window.location.href = '/';
  };

  if (
    analytics.trackPageView &&
    analytics.trackUpsellExit &&
    analytics.trackPaymentExit
  ) {
    analytics.trackPageView({ pageName: 'completed_subscription' });
    analytics.trackUpsellExit({
      destination: UPSELL_FROM.NEW_SCREEN,
      exitType: UPSELL_FROM.UPGRADE_SUCCESS,
    });
    analytics.trackPaymentExit({ exitType: UPSELL_FROM.UPGRADE_SUCCESS });
  }

  return (
    <RecurlyHOC>
      <HeroContainer transformValue="-13rem">
        <HeroStyles>
          <Logo />
          <HeroText>{heroText}</HeroText>
        </HeroStyles>
      </HeroContainer>
      <RecurlyFormWrap>
        <H3>{explainText}</H3>
        <ConfirmationButtonWrap>
          <FilledButton
            data-test="checkout-button"
            isBlock
            onClick={redirect}
            styleType="cta"
            tabIndex={3}
          >
            Start Listening
          </FilledButton>
        </ConfirmationButtonWrap>
      </RecurlyFormWrap>
    </RecurlyHOC>
  );
}

export default recurlyConfirmation;
